//Fonts
* {
  font-family: 'Plus Jakarta Sans';
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  src: url('/assets/fonts/PlusJakartaSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/PlusJakartaSans-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  src: url('/assets/fonts/PlusJakartaSans-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-weight: 800;
  src: url('/assets/fonts/PlusJakartaSans-Bold.woff2') format('woff2');
}

//END Fonts
