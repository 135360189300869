//app header bar
.app-headerbar {
  &__links {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    &-item {
      opacity: 0.8;
      transition: all 0.25s ease-in;

      &:hover {
        opacity: 1;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}
