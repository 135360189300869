//Buttons
@import 'variables';

.btn-primary {
  @include button-variant(
    var(--primary),
    transparent,
    $white,
    var(--primary-hover),
    transparent,
    $white,
    var(--primary-hover),
    transparent,
    $white,
    $color-grey-disabled,
    $white,
    $white
  );
}

.btn-secondary {
  @include button-variant(
    var(--secondary),
    transparent,
    $white,
    var(--secondary-hover),
    transparent,
    $white,
    var(--secondary-hover),
    transparent,
    $white,
    $color-grey-disabled,
    $white,
    $white
  );
}

.btn-outline {
  @include button-outline-variant(
    lighten($color-font, 0%),
    lighten($color-font, 10%),
    transparent,
    lighten($color-font, 5%),
    lighten($color-font, 5%)
  );
}

.btn-dark {
  @include button-variant(
    darken($color-grey-dark, 0%),
    darken($color-grey-dark, 0%),
    lighten($white, 5%),
    darken($color-grey-dark, 10%),
    darken($color-grey-dark, 10%),
    darken($white, 5%),
    lighten($color-grey-dark, 0%),
    lighten($color-grey-dark, 0%),
    darken($white, 5%),
    $color-grey-disabled,
    $white,
    $white
  );
}

.btn-danger {
  @include button-variant(
    darken($red, 0),
    darken($red, 0%),
    lighten($white, 5%),
    darken($red, 10%),
    darken($red, 10%),
    darken($white, 5%),
    lighten($red, 0%),
    darken($red, 30%),
    darken($white, 5%),
    $color-grey-disabled,
    $white,
    $white
  );
}
