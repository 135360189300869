@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/maps';
@import 'bootstrap/mixins';
@import 'bootstrap/utilities';

$widget-spacer: $spacer;
$btn-white-space: nowrap;

// GENERIC
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-grid-classes: true;

// box Shadows
$box-shadow-default:
  0 0.46875rem 2.1875rem rgba(darken($primary, 50%), 0.03),
  0 0.9375rem 1.40625rem rgba(darken($primary, 50%), 0.03),
  0 0.25rem 0.53125rem rgba(darken($primary, 50%), 0.05),
  0 0.125rem 0.1875rem rgba(darken($primary, 50%), 0.03);

$box-shadow-default-right:
  0.46875rem 0 2.1875rem rgba(darken($primary, 50%), 0.03),
  0.9375rem 0 1.40625rem rgba(darken($primary, 50%), 0.03),
  0.25rem 0 0.53125rem rgba(darken($primary, 50%), 0.05),
  0.125rem 0 0.1875rem rgba(darken($primary, 50%), 0.03);

$box-shadow-default-inverse:
  0 -0.46875rem 2.1875rem rgba(darken($primary, 50%), 0.03),
  0 -0.9375rem 1.40625rem rgba(darken($primary, 50%), 0.03),
  0 -0.25rem 0.53125rem rgba(darken($primary, 50%), 0.05),
  0 -0.125rem 0.1875rem rgba(darken($primary, 50%), 0.03);

$box-shadow-lg-default:
  0 0.76875rem 2.4875rem rgba($gray-800, 0.3),
  0 1.3375rem 1.70625rem rgba($gray-800, 0.3),
  0 0.55rem 0.53125rem rgba(darken($gray-800, 50%), 0.05),
  0 0.225rem 0.4375rem rgba($gray-800, 0.3);

$sidebar-box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
$app-container-bg: #f1f4f6;
$app-sidebar-bg: #ffffff;
$app-header-bg: #fafbfc;
$app-header-logo-bg: rgba(255, 255, 255, 0.9);
$app-header-real-height: 80px;

//BOOTSTRAP VARIABLES

//Colors
// https://coolors.co/fea839
$color-primary: #fea839;
$color-primary-hover: #fe950d;
$color-primary-tint: #fece8e;
$color-red: #fe394d;
$color-yellow: #efff38;
$color-blue: #398efe;
$color-blue-light: #39d0fe;
$color-blue-dark: #394dfe;
$color-purple: #a939fe;
$color-green: #8efe39;
$color-green-light: #39fea9;
$color-green-dark: #39fe46;
$color-magenta: #fe39f1;
$color-grey-dark: #9c9c9c;
$color-grey: #bfbfbf;
$color-grey-disabled: #dadada;
$color-grey-light: #e3e3e3;
$color-grey-lighter: #f3f3f3;
$color-font: #4e4e4e;
$primary-light: #fff6ea;
$white: #fff;
$black: #000;
$shadow: #848484;
$color-secondary: $color-blue;
$color-secondary-hover: $color-blue-dark;
$color-secondary-tint: #c2ddf6;

//$link-color: #212529;
$body-color: #4e4e4e;
$border-color: #ced4da;

//correspondent theme customs
$color-primary-wl: #61aaef;
$color-primary-tint-wl: #8fcbfd;
$color-primary-hover-wl: #3c89d8;
$color-secondary-wl: #12529b;
$color-secondary-hover-wl: #03357a;

//gray colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$grays: ();

$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

//bootstrap colors

//--#{$color}
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff4b22;
$orange: #fea430;
$yellow: #dd5600;
$green: #11b60c;
$teal: #20c997;
$cyan: #2fa4e7;
$blue-light: #d5d9eb;
$primary-dark: #ff960f;
$primary: $color-primary;
$secondary: $color-blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $blue-light;
$dark: $color-grey-dark;

$theme-colors: map-merge(
  $theme-colors,
  (
    'body': $body-color,
  )
);

//Gradients
$gradient-blue: linear-gradient(135deg, $color-blue 100%, $color-blue-dark 0%);
$gradient-blue-dark: linear-gradient(
  135deg,
  $color-blue 0%,
  $color-blue-dark 100%
);
$gradient-orange: linear-gradient(135deg, $primary 0, $primary-dark 100%);
$gradient-grey: linear-gradient(135deg, #adadad 0, #565450 100%);
$color-gradient-wl: linear-gradient(
  135deg,
  $color-secondary-wl 0%,
  $color-secondary-hover-wl 100%
);

//Fonts
$stack-sans-serif: Arial, Helvetica, sans-serif;
$stack-metronic: 'Metronic', $stack-sans-serif;
$headings-font-weight: 400;
$font-family-base: Roboto, 'Helvetica Neue', Arial, 'Noto Sans';
$font-size-base: 1rem;
$font-size-lg: calc($font-size-base * 1.35);
$font-size-sm: calc($font-size-base * 1.1);
$font-size-xs: calc($font-size-base / 1.25);

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  xs: $font-size-xs,
);

//buttons
$btn-transition:
  color 0.15s,
  background-color 0.15s,
  border-color 0.15s,
  box-shadow 0.15s;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;

//Pagination
$pagination-focus-box-shadow: none;

//Cards
$card-cap-bg: $white;
$card-border-color: rgba(darken($primary, 25%), 0.125);

//Dropdown
$dropdown-min-width: 16rem;
$dropdown-link-hover-bg: #d1f3ff;
$dropdown-item-padding-y: 0.4rem;
$dropdown-padding-y: 0.65rem;
$dropdown-box-shadow:
  0 0.46875rem 2.1875rem rgba(darken($primary, 50%), 0.03),
  0 0.9375rem 1.40625rem rgba(darken($primary, 50%), 0.03),
  0 0.25rem 0.53125rem rgba(darken($primary, 50%), 0.05),
  0 0.125rem 0.1875rem rgba(darken($primary, 50%), 0.03);

//Shadows
$btn-box-shadow: none;
$btn-active-box-shadow: none;
$box-shadow-1: rgba(0, 0, 0, 0.1) 0px 3px 8px;
$box-shadow-2: rgba(0, 0, 0, 0.25) 0px 5px 15px;
$box-shadow-3: rgba(0, 0, 0, 0.1) 0px 0px 4px;
$blue-shadow:
  0 4px 12px
    rgba(
      $color: $color-blue,
      $alpha: 0.2,
    ),
  0 8px 24px
    rgba(
      $color: $color-blue,
      $alpha: 0.4,
    );
$orange-shadow:
  0 4px 12px
    rgba(
      $color: $primary-dark,
      $alpha: 0.15,
    ),
  0 8px 24px
    rgba(
      $color: $primary-dark,
      $alpha: 0.3,
    );
$gray-shadow-strong:
  0 8px 16px
    rgba(
      $color: $shadow,
      $alpha: 0.2,
    ),
  0 16px 32px
    rgba(
      $color: $shadow,
      $alpha: 0.4,
    );
$gray-shadow:
  0 4px 12px
    rgba(
      $color: $shadow,
      $alpha: 0.06,
    ),
  0 8px 24px
    rgba(
      $color: $shadow,
      $alpha: 0.12,
    );

//Breakpoints
// $sm: "min-width: 576px";
// $md: "min-width: 768px";
// $lg: "min-width: 1024px";
// $xl: "min-width: 1200px";
// $xxl: "min-width: 1400px";
// $xxxl: "min-width: 1600px";

//FORM
$form-floating-padding-y: 1rem;
$form-floating-padding-x: 0;
$form-floating-height: add(2.5rem, $input-height-border);
$form-floating-label-transform: scale(0.85) translateY(-1.2rem) translateX(0);
$form-range-track-bg: $color-blue;
$input-group-addon-bg: lighten($dropdown-link-hover-bg, 3%);
$input-bg: transparentize($white, 1);
$input-focus-bg: transparentize($white, 1);
$input-focus-bg: transparentize($white, 1);
$input-focus-box-shadow: none;

//BORDER
$border-radius: 0.375rem;

// Tables
$table-accent-bg: rgba($black, 0.03);
$table-hover-bg: $dropdown-link-hover-bg;
$table-active-bg: $table-hover-bg;
$table-border-color: $gray-200;
$table-head-bg: $gray-100;
$table-head-color: $gray-600;
$table-cell-padding: 0.55rem;
$table-cell-padding-sm: 0.3rem;
$table-border-width: 1px;
$table-group-separator-color: $color-font;

// Popovers
$popover-max-width: 320px;
$popover-box-shadow: $box-shadow-default;
$popover-border-color: $card-border-color;
$popover-header-bg: $white;

//Modal
$modal-inner-padding: 1rem;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;

// Color Modifiers

$link-color: map-get($theme-colors, 'primary');
$component-active-bg: map-get($theme-colors, 'primary');
$custom-control-indicator-checked-disabled-bg: rgba(
  map-get($theme-colors, 'primary'),
  0.5
);
$form-feedback-valid-color: map-get($theme-colors, 'success');
$form-feedback-invalid-color: map-get($theme-colors, 'danger');
$progress-bar-bg: map-get($theme-colors, 'primary');
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-focus-border-color: lighten($component-active-bg, 25%);
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$dropdown-link-active-bg: $component-active-bg;
$nav-pills-link-active-bg: $component-active-bg;
$pagination-active-bg: $component-active-bg;
$list-group-active-bg: $component-active-bg;

$list-group-border-color: rgba($primary, 0.08);

// Mixins

$input-border-radius: 6px;
$btn-border-radius: 6px;
$input-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;

$btn-disabled-opacity: 1;
$form-feedback-font-size: 0.88rem;

$custom-theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'white': $white,
    'main': $color-font,
    'color-font': $color-font,
    'grey-dark': $color-grey-dark,
  )
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  '$key',
  'text'
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

//Carousel
$carousel-control-width: 10%;

// Utility classses options
$utilities: map-merge(
  (
    'font-size': (
      rfs: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
      responsive: true,
    ),
    'width': (
      property: width,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
      ),
      responsive: true,
    ),
    'height': (
      property: height,
      class: h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        100vh: 100vh,
      ),
      responsive: true,
    ),
  ),
  $utilities,
  (
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          responsive: true,
        )
      ),
    'font-size':
      map-merge(
        map-get($utilities, 'font-size'),
        (
          responsive: true,
        )
      ),
  )
);
