@import 'variables';
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

ol,
ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover,
a:active,
button:hover,
button:active {
  text-decoration: none !important;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

// because of the default h3 and h4 styles in bootstrap is 400, and in version 5.2.3 we have no for 500 style
h3 {
  font-weight: 500;
}
h4 {
  font-weight: 500;
}

//Remove arrows from input type=""number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: inherit;
  pointer-events: auto;
  &:active {
    background-color: $color-grey-disabled;
    color: $white;
    border-color: $white;
  }
}
.popover {
  z-index: 1601;
}
