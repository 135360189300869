ngx-spinner {
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;

      &.loading-text {
        color: #fff;
        margin-top: 30px;
        text-align: center;

        p {
          margin: 0;
          font-size: 18px;
        }
      }
    }
  }
}
