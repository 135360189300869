//underwriter
.component-wrapper {
  .card {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border: none;
    padding: 1.25rem;
  }

  .tab-content {
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
  }

  .widget-content-wrapper {
    @media (max-width: 767px) {
      flex-direction: column;

      .widget-content-right {
        margin: 0;

        .widget-numbers {
          font-size: 1.2rem;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
