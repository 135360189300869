@import 'variables';

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: $color-grey-dark;
  display: inline-block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: var(--secondary);
}
