// tooltip styles
@import 'variables';

.dx-popup-wrapper > .dx-overlay-content {
  box-shadow: $box-shadow-1;
  border: none;
}

.dx-overlay-content {
  text-align: center;
}

.dx-popover-arrow {
  display: none;
}

.dx-popover-arrow:after {
  border: none;
}
// END tooltip styles

.box {
  width: 40%;
  margin-bottom: 40px;
}
.dx-texteditor {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 11%);
}

.dx-texteditor.dx-state-focused {
  border-color: $color-blue-dark !important;
}

.dx-tag-content {
  background: $color-primary;
  color: white;
}
.dx-tag-remove-button {
  top: -1px;
  &::after,
  &::before {
    background: white;
  }
}

.dx-show-clear-button .dx-icon-clear {
  color: $color-grey-dark;
}

// progress bar
.dx-progressbar-container {
  height: 10px;
  border: none;
  background: none;
  .dx-progressbar-range {
    border: none;
    border-radius: 4px;
    background: var(--primary);
  }
}
.dx-progressbar-status {
  font-style: italic;
  margin-top: 10px;
}
//END progress bar

.dx-state-focused {
  border: 0 !important;
}
.dx-checkbox-icon {
  border-color: #000;
}

.dx-gallery .dx-gallery-nav-button-prev,
.dx-gallery .dx-gallery-nav-button-next {
  width: 40px;
}

.dx-multiview-wrapper {
  border-bottom: none !important;
}

.dx-row.dx-data-row {
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
}

dx-drop-down-box,
dx-date-box {
  height: 42px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 11%);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.dx-slider-range.dx-slider-range-visible {
  border: 1px solid var(--primary) !important;
  background: var(--primary) !important;

  .dx-slider-handle {
    background-color: var(--primary) !important;
  }
}
.dx-slider .dx-tooltip-wrapper .dx-overlay-content {
  transform: translate(-21px, -21px) !important;
  background: transparent !important;
}

.text-danger {
  .dx-label {
    color: $danger !important;
  }
}

.custom-dx-tab-styles {
  .dx-multiview-wrapper {
    padding-bottom: 20px;
  }

  .dx-tabs {
    border-bottom: 2px solid #000 !important;

    .dx-tabs-wrapper {
      display: inline !important;
      font-size: 18px !important;

      .dx-item {
        box-shadow:
          inset 0 1px #ddd,
          inset -1px 0 #ddd,
          inset 0 -1px #ddd,
          inset 1px 0 #ddd !important;
      }

      .dx-tab-selected {
        font-weight: bold;
        background-color: #ccc;
        border-top: 2px solid #000;
        border-left: 2px solid #000;
        border-right: 2px solid #000;

        &:after {
          content: '' !important;
          border: none;
          border-bottom: 3px solid #ccc;
          position: absolute;
          bottom: -2px;
        }
      }
    }
  }
}

.custom-dx-range-view {
  .dx-popup-content {
    padding-left: 7px;
  }
  .dx-slider-bar {
    margin: 14px 10px;
  }
}

.custom-dx-checkbox-item {
  .dx-state-disabled .dx-checkbox,
  .dx-state-disabled.dx-checkbox {
    background-color: white;
  }

  .dx-state-disabled .dx-checkbox .dx-checkbox-icon,
  .dx-state-disabled .dx-checkbox-text,
  .dx-state-disabled.dx-checkbox .dx-checkbox-icon {
    opacity: 1;
  }
}

/** DevExpress Theme **/
.dx-theme-generic-typography,
.dx-theme-generic-typography input,
.dx-theme-generic-typography textarea,
.dx-widget,
.dx-widget input,
.dx-widget textarea,
.dx-field input,
.dx-field textarea,
.dx-field,
.dx-overlay-wrapper,
.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea,
.dx-menu-base,
.dx-menu-base input,
.dx-menu-base textarea,
.dx-datagrid-column-chooser,
.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area input,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area textarea,
.dx-treelist-column-chooser,
.dx-treelist-column-chooser input,
.dx-treelist-column-chooser textarea {
  font-family: $font-family-base;
}
