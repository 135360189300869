@import '../assets/scss/variables';

.overflow-inherit {
  overflow: inherit !important;
}

.card .widget-content {
  overflow: visible;
}

.form-control.ng-touched.ng-invalid {
  border-color: $red;
}

.btn {
  -webkit-font-smoothing: subpixel-antialiased;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
}

.form-floating {
  & > label {
    opacity: 0.8;
  }
}

.max-width-container {
  @media (min-width: 1600px) {
    max-width: 1540px;
  }
}

.body-full-height {
  min-height: unquote('calc( 100vh - #{$app-header-real-height} )');
}

.swal2-container {
  z-index: 2000 !important; //should be higest
  .swal2-actions {
    button {
      padding: 0.375rem 0.75rem;
      &:focus {
        box-shadow: none;
      }
      &.swal2-confirm {
        background-color: var(--secondary);
        &:hover {
          background-color: var(--secondary-hover);
        }
      }
      &.swal2-deny {
        background-color: $color-red;
      }
      &.swal2-cancel {
        background-color: $color-grey-dark;
      }
    }
  }
}

.pac-container {
  z-index: 103;
}
.popover {
  z-index: 101;
}

.modal-open {
  .pac-container {
    z-index: 1303;
  }
  .popover {
    z-index: 1301;
  }
}

.form-control[readonly] {
  background-color: $gray-200;
}

// fix partner settings page handle is moving on deselect
app-settings {
  dx-slider {
    .dx-slider-handle {
      border: none !important;
    }
  }
}

app-loans {
  dx-data-grid {
    .dx-row {
      .dx-command-expand.dx-datagrid-expand {
        &.action-required {
          .dx-datagrid-group-closed,
          .dx-datagrid-group-opened {
            color: red;
          }
        }
        .dx-datagrid-group-closed,
        .dx-datagrid-group-opened {
          font-size: 25px;
        }
      }
      .action-column {
        padding: 5px;
        img {
          height: 23px;
        }
      }
    }
  }
}
dx-data-grid {
  .dx-row {
    &.partner-row {
      background-color: rgb(135 210 255 / 76%);
      &.action-required {
        background-color: rgb(71 162 217);
      }
    }
    &.ns-row {
      background-color: rgb(255 203 135 / 76%);
      &.action-required {
        background-color: rgb(239 173 88);
      }
    }
  }
}

fieldset {
  legend {
    width: auto !important;
  }
}
